@import "variables.scss";
@import "mixins.scss";
body {
  font-family: "Lora", "Helvetica Neue", Helvetica, Arial, sans-serif;

  position: relative;

  width: 100%;
  height: 100%;

  color: white;
  background-color: black;
}

html {
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Cabin", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;

  margin: 0 0 35px;

  letter-spacing: 1px;
  text-transform: uppercase;
}

p {
  font-size: 16px;
  line-height: 1.5;

  margin: 0 0 25px;
  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 1.6;

    margin: 0 0 35px;
  }
}

a {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  color: $primary;
  &:focus,
  &:hover {
    text-decoration: none;

    color: darken($primary, 20%);
  }
}

#mainNav {
  font-family: "Cabin", "Helvetica Neue", Helvetica, Arial, sans-serif;

  margin-bottom: 0;

  text-transform: uppercase;

  border-bottom: 1px solid fade-out(white, 0.7);
  background-color: black;
  .navbar-toggler {
    font-size: 14px;

    padding: 11px;

    color: white;
    border: 1px solid white;
  }
  .navbar-brand {
    font-weight: 700;
    text-shadow: 0 0 10px black, 0 0 10px black, 0 0 10px black;
  }
  .nav-link {
    text-shadow: 0 0 10px black, 0 0 10px black, 0 0 10px black;
  }
  a {
    color: white;
  }
  .navbar-nav {
    .nav-item {
      -webkit-transition: background 0.3s ease-in-out;
      -moz-transition: background 0.3s ease-in-out;
      transition: background 0.3s ease-in-out;
      &:hover {
        color: fade(white, 80%);
        outline: none;
        background-color: transparent;
      }
      &:active,
      &:focus {
        outline: none;
        background-color: transparent;
      }
    }
  }
  @media (min-width: 992px) {
    padding-top: 20px;
    padding-bottom: 20px;

    -webkit-transition: background 0.3s ease-in-out,
      padding-top 0.3s ease-in-out, padding-bottom 0.3s;
    -moz-transition: background 0.3s ease-in-out, padding-top 0.3s ease-in-out,
      padding-bottom 0.3s;
    transition: background 0.3s ease-in-out, padding-top 0.3s ease-in-out,
      padding-bottom 0.3s;
    letter-spacing: 1px;

    border-bottom: none;
    background: transparent;
    &.navbar-shrink {
      padding-top: 10px;
      padding-bottom: 10px;

      border-bottom: 1px solid fade-out(white, 0.7);
      background: black;
    }
    .nav-link.active {
      outline: none;
      background-color: rgba(white, 0.3);
      &:hover {
        color: white;
      }
    }
  }
}

.archivehead {
  display: table;

  width: 100%;
  height: auto;
  padding: 200px 0;

  text-align: center;

  color: white;
  background-color: black;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .intro-body {
    display: table-cell;

    vertical-align: middle;
    .brand-heading {
      font-size: 50px;
      text-shadow: 0 0 50px black, 0 0 50px black, 0 0 50px black,
        0 0 500px black, 0 0 100px black, 0 0 100px black, 0 0 100px black,
        0 0 100px black;
    }
    .intro-text {
      font-size: 18px;
    }
  }
  @media (min-width: 768px) {
    height: 100%;
    padding: 0;
    .intro-body {
      .brand-heading {
        font-size: 100px;
      }
      .intro-text {
        font-size: 22px;
      }
    }
  }
}

.masthead {
  display: table;

  width: 100%;
  height: auto;
  padding: 200px 0;

  text-align: center;

  color: white;
  background: url("../img/con-img/BGE186.jpg?width=1920");
  background-size: cover;
  background-position: bottom;
  .intro-body {
    display: table-cell;

    vertical-align: middle;
    .brand-heading {
      font-size: 50px;
      text-shadow: 0 0 50px black, 0 0 50px black, 0 0 50px black,
        0 0 500px black, 0 0 100px black, 0 0 100px black, 0 0 100px black,
        0 0 100px black;
    }
    .intro-text {
      font-size: 18px;
    }
  }
  @media (min-width: 768px) {
    height: 83%;
    padding: 0;
    .intro-body {
      .brand-heading {
        font-size: 100px;
      }
      .intro-text {
        font-size: 22px;
      }
    }
  }
}

#background-image {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-image: url("../img/old-parchment.jpg");
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.btn-circle {
  font-size: 26px;

  width: 55px;
  height: 55px;
  margin-top: 15px;
  line-height: 45px;

  -webkit-transition: background 0.3s ease-in-out;
  -moz-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;

  color: white;
  border: 2px solid white;
  border-radius: 100% !important;
  background: rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 25px black, 0 0 25px black, 0 0 25px black;
  &:focus,
  &:hover {
    color: white;
    outline: none;
    background: fade-in(black, 0.9);
  }
}

#map {
  width: 100%;
  height: 300px;
}
@media (min-width: 992px) {
  .content-section {
    padding-top: 100px;
    padding-bottom: 200px;
  }
  #map {
    height: 350px;
  }
}

.btn {
  font-family: "Cabin", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;

  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;

  border-radius: 0;
}

.btn-default {
  color: $primary;
  border: 1px solid $primary;
  background-color: transparent;
  &:focus,
  &:hover {
    color: black;
    border: 1px solid $primary;
    outline: none;
    background-color: $primary;
  }
}

ul.banner-social-buttons {
  margin-top: 0;
  @media (max-width: 1199px) {
    margin-top: 15px;
  }
  @media (max-width: 767px) {
    li {
      display: block;

      margin-bottom: 20px;
      padding: 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

footer {
  padding: 50px 0;
  p {
    font-size: 14px;

    margin: 0;
  }
}

img::selection {
  background: transparent;
}

img::-moz-selection {
  background: transparent;
}

body {
  -webkit-tap-highlight-color: fade-out(white, 0.8);
}

.about-section {
  &.left {
    float: left;
    max-width: 20%;
  }
  &.right {
    float: right;
    max-width: 20%;
  }
  &.center {
    float: none;
    max-width: 60%;
  }
}

.side-image {
  display: flex;
  align-items: center;
  height: 50%;
}

.about-text {
  max-width: 90%;
  text-align: justify;
  text-align-last: center;
}

.about-header {
  text-align: center;
}

.register-form {
  line-height: 44px;
  height: 48px;
  font-size: 0pt;
  margin: 20pt;
  .register-field {
    font-size: 12pt;
    height: 100%;
    vertical-align: top;
    border: solid 1px #ba872d;
    background-color: #ba872d70;
    color: black;
    font-family: Cabin, "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-align: center;
  }
  .register-field-amount {
    max-width: 80pt;
  }
  #register-name {
    min-width: 230pt;
  }
}

.bdlcon-description {
  text-align: justify;
  text-align-last: center;
  ul {
    text-align-last: left;
  }
}

.con-abgesagt {
  background-color: rgba(255, 0, 0, 0.4);
  border-style: solid;
  padding: 30px;
}

h3 {
  font-size: 16pt;
  margin-block-end: 5pt;
  margin-top: 30pt;
}

.allies-section {
  padding-bottom: 100px;
}

.allies-banner {
  float: center;
  max-width: 30%;
  img {
    max-width: 100%;
    height: auto;
    padding: 0.25rem;
  }
  margin-left: auto;
  margin-right: auto;
}

.allies-text {
  text-align: justify;
  text-align-last: center;
  max-width: 70%;
  display: flex;
}

.wappen-section {
  color: black;
  // background: url("../img/old-parchment.jpg") no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .btn-default {
    color: black;
    border: 1px solid #ba872d;
    background-color: #ba872d70;
    &:focus,
    &:hover {
      color: black;
      border: 1px solid #ba872d;
      outline: none;
      background-color: #ba872d;
    }
  }
}

.wappen-banner {
  float: left;
  max-width: 30%;
  img {
    max-width: 100%;
    height: auto;
    margin-top: 1rem;
  }
  margin-left: auto;
  margin-right: 3rem;
}

.wappen-text {
  text-align: justify;
  text-align-last: center;
  max-width: 70%;
  display: flex;
}

.registration-fallback-link {
  color: #0d3eb3;
  &:focus,
  &:hover {
    text-decoration: none;
    color: #1d9b6c;
  }
}

//.registration-fallback-modal {
//  display: none; /* Hidden by default */
//  position: fixed; /* Stay in place */
//  z-index: 1; /* Sit on top */
//  left: 0;
//  top: 0;
//  width: 100%; /* Full width */
//  height: 100%; /* Full height */
//  overflow: auto; /* Enable scroll if needed */
//  background-color: rgb(0, 0, 0); /* Fallback color */
//  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
//}

#impressum-open {
  text-align: center;
  margin-bottom: 30px;
}

#lz-logo-a {
  max-width: 30%;
  margin-right: 20px;
  max-height: 100%;
  float: left;
}

#lz-logo-img {
  max-width: 100%;
  max-height: 100%;
}

.lz-rabatt {
  margin-top: 10px;
  //display: inline-block;
  display: flex;
  align-items: center;
}

#gallery {
  width: 100%;
  height: calc(80vw * 0.5625);
}

#gallery-con {
  width: 150%;
  height: calc(80vw * 0.5625);
}

.img-thumbnail {
  padding: 0;
}

.map-section {
  padding-bottom: 0;
  height: auto !important;
}

// Leaflet tile borders (for debugging)
/*
.leaflet-tile {
  border: solid black 1px;
}
 */

#map-owner-display {
  border: 1px solid #42dca3;
  color: #42dca3;
  background-color: transparent;
  font-family: "Cabin", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1.5;
  margin-bottom: 3px;
}

#map-owner-display-name {
  font-size: 1.25rem;
}

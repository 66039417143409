$clrParch: #ba872d;
$clrParchDark: #ba872d70;

$clrParchLink: rgb(38, 131, 154);
$clrParchLinkDark: rgb(19, 62, 73);

.section-black {
  background-color: black;
  width: 100%;
  @extend .py-5;
  @extend .my-0;
}

.parchment {
  color: black;
  // background: url("../img/old-parchment.jpg");
  // background-size: cover;

  th {
    border-top: 0;
  }

  td,
  th {
    border-color: black !important;
  }

  .btn-default {
    color: black;
    border: 1px solid;
    background-color: $clrParchDark;

    &:focus,
    &:hover {
      color: black;
      border: 1px solid $clrParch;
      outline: none;
      background-color: $clrParch;
    }
  }

  a {
    color: $clrParchLink;

    &:focus,
    &:hover {
      color: $clrParchLinkDark;
    }
  }
}

.section {
  @extend .col;
  @extend .col-lg-8;
  @extend .col-xl-6;
  @extend .mx-auto;
  @extend .px-5;

  text-align: justify;

  text-align-last: center;
}

input {
  @extend .border;
  border-color: $clrParch !important;
  background-color: $clrParchDark;
  font-family: Cabin, "Helvetica Neue", Helvetica, Arial, sans-serif;
  min-height: 50px;
}

#blutinseln-map {
  height: 600px;
  width: 100%;
}

#map {
  margin-bottom: 600px !important;
}

.flex-img-container {
  display: flex;

  a {
    min-width: 1px;
    margin-left: 1.5%;
    margin-right: 1.5%;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.archive-warning {
  background-color: rgba(255, 0, 0, 0.4);
  border-style: solid;
  padding: 30px;
}

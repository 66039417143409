@import "~bootstrap/scss/bootstrap";
@import "grayscale";
@import "bdl";

.table {
  th {
    background-color: transparent;
  }

  td {
    background-color: transparent;
  }
}
